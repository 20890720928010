/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/
const convert_multi_point_to_points = (geojson) => {
  const features = geojson.features.flatMap((feature) => {
    if (feature.geometry.type === "MultiPoint") {
      return feature.geometry.coordinates.map((coord) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: coord,
        },
        properties: feature.properties,
      }));
    }
    return feature;
  });
  return {
    type: "FeatureCollection",
    features,
  };
};
const title = "layer_child";

class LayerBasic extends Component {
  componentDidMount() {
    const { map_object } = this.props.layer;

    if (map_object) {
      // Check if the style is loaded
      if (map_object.isStyleLoaded()) {
        this.render_layer();
      } else {
        // Wait for the style to be loaded
        map_object.once("styledata", this.render_layer);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { map_object } = this.props.layer;

    if (map_object) {
      // Check if the style is loaded
      if (map_object.isStyleLoaded()) {
        this.render_layer();
        const { overlay_visibility_status } = this.props.properties;
        if (map_object.getLayer(title)) {
          map_object.setLayoutProperty(
            title,
            "visibility",
            overlay_visibility_status ? "visible" : "none"
          );
        }
      } else {
        // Wait for the style to be loaded before rendering
        map_object.once("styledata", this.render_layer);
      }
    }
  }

  render_layer = () => {
    const { overlay_visibility_status } = this.props.properties;
    const { map_object } = this.props.layer;
    const geo_layer = this?.props?.geo_layer;

    const { _id } = geo_layer;
    const features = geo_layer?.geojson?.features || [];

    const geojson = {
      type: "FeatureCollection",
      features: features,
    };
    const type = geo_layer?.type;
    const default_style_key = geo_layer?.default_style_key;
    const style_array = geo_layer?.style_array || [];
    let properties_raw = geo_layer?.properties;
    const is_cluster = geo_layer?.is_cluster;

    let shape_type;

    let paint_object = {};
    let paint_object_line_polygon = {};

    let visibility = overlay_visibility_status ? "visible" : "none";

    let geojson_point;
    //determine the shape type based on the geometry type
    switch (type) {
      case "Point":
      case "MultiPoint":
        shape_type = "circle";

        paint_object = {
          "circle-color": "#1a649d",
          "circle-opacity": 1,
          "circle-radius": 3,
          "circle-stroke-color": "#fff",
          "circle-stroke-width": 2,
          "circle-stroke-opacity": 1,
          "circle-blur": 0,
          "circle-pitch-alignment": "map",
          "circle-pitch-scale": "map",
          "circle-translate": [0, 0],
          "circle-translate-anchor": "map",
        };
        break;
      case "LineString":
      case "MultiLineString":
        shape_type = "line";

        paint_object = {
          "line-color": "#1a649d",
          "line-width": 2,
          "line-opacity": 1,
        };
        break;
      case "Polygon":
      case "MultiPolygon":
        shape_type = "fill";

        paint_object = {
          "fill-color": "#1a649d",
          "fill-opacity": 0.7,
        };
        paint_object_line_polygon = {
          "line-color": "#000",
          "line-width": 1,
          "line-opacity": 1,
        };
        break;
      default:
        shape_type = "circle";

        paint_object = {
          "circle-color": "#1a649d",
          "circle-opacity": 1,
          "circle-radius": 3,
          "circle-stroke-color": "#fff",
          "circle-stroke-width": 2,
          "circle-stroke-opacity": 1,
          "circle-blur": 0,
          "circle-pitch-alignment": "map",
          "circle-pitch-scale": "map",
          "circle-translate": [0, 0],
          "circle-translate-anchor": "map",
        };
        break;
    }

    /*
    CASE-CASE WARNA
    
    CASE 1 : dari apply color (front end pie chart sidebar kanan)
    CASE 2 : dari preview jika aktif (sedang edit layer style dan preview on)
    CASE 3 : dari style_array (dari DB, sudah simpan)
    */

    const is_error = !!properties_raw?.[0]?.["LineString"];
    if (is_error) {
      if (type === "Point" || type === "MultiPoint") {
        properties_raw = properties_raw?.[0]?.["Point"];
      } else if (type === "LineString" || type === "MultiLineString") {
        properties_raw = properties_raw?.[0]?.["LineString"];
      } else if (type === "Polygon" || type === "MultiPolygon") {
        properties_raw = properties_raw?.[0]?.["Polygon"];
      }
    }

    if (style_array.length > 0) {
      //CASE 3: sudah simpan ke DB

      const paint_object_db =
        style_array.find((item) => item?.key === default_style_key)
          ?.paint_object || {};
      if (shape_type === "fill") {
        paint_object = {
          "fill-color": paint_object_db["fill-color"],
          "fill-opacity": paint_object_db["fill-opacity"],
          "fill-outline-color": "transparent",
        };
        paint_object_line_polygon = {
          "line-color": paint_object_db["line-color"],
          "line-width": paint_object_db["line-width"],
          "line-opacity": paint_object_db["line-opacity"],
        };
      } else if (shape_type === "line") {
        paint_object = {
          "line-color": paint_object_db["line-color"],
          "line-width": paint_object_db["line-width"],
          "line-opacity": paint_object_db["line-opacity"],
        };
      } else {
        paint_object = paint_object_db;
      }
    }

    const cluster_id = _id + "_cluster";

    if (map_object !== null) {
      //SOURCE
      if (!map_object?.getSource(_id)) {
        map_object.addSource(_id, {
          type: "geojson",
          data: geojson,
          cluster: false,
        });
        if (type === "Point") {
          //untuk layer tipe Point perlu source cluster meskipun sedang tidak mode cluster untuk transisi pengeditan yang lebih smooth
          if (!map_object?.getSource(cluster_id)) {
            map_object.addSource(cluster_id, {
              type: "geojson",
              data: geojson,
              cluster: true,
              clusterMaxZoom: 14,
              clusterRadius: 50,
            });
          }
        } else if (type === "MultiPoint") {
          geojson_point = convert_multi_point_to_points(geojson);
          if (!map_object?.getSource(cluster_id)) {
            map_object.addSource(cluster_id, {
              type: "geojson",
              data: geojson_point,
              cluster: true,
              clusterMaxZoom: 14,
              clusterRadius: 50,
            });
          }
        }
      } else {
        map_object.getSource(_id).setData(geojson); //untuk layer pada umumnya, jika sudah ada sourcenya maka update data geojson
        if (type === "Point") {
          //untuk layer tipe Point dan MultiPoint, edit atau tambah juga layer tipe custernya
          if (!map_object?.getSource(cluster_id)) {
            map_object.addSource(cluster_id, {
              type: "geojson",
              data: geojson,
              cluster: true,
              clusterMaxZoom: 14,
              clusterRadius: 50,
            });
          } else {
            map_object.getSource(cluster_id).setData(geojson);
          }
        } else if (type === "MultiPoint") {
          geojson_point = convert_multi_point_to_points(geojson);
          if (!map_object?.getSource(cluster_id)) {
            map_object.addSource(cluster_id, {
              type: "geojson",
              data: geojson_point,
              cluster: true,
              clusterMaxZoom: 14,
              clusterRadius: 50,
            });
          } else {
            map_object.getSource(cluster_id).setData(geojson_point);
          }
        }
      }

      //LAYER
      if (!map_object.getLayer(_id)) {
        if (shape_type === "fill") {
          //untuk kasus polygon,
          //perlu layer line karena poligon maplibre tidak bisa ganti ketebalan garis luar untuk fill
          map_object.addLayer({
            id: _id,
            source: _id,
            type: shape_type,
            paint: paint_object,
            layout: {
              visibility: visibility,
            },
          });
          map_object.addLayer({
            id: _id + "_line_polygon",
            source: _id,
            type: "line",
            paint: paint_object_line_polygon,
            layout: {
              visibility: visibility,
            },
          });
        } else if (is_cluster && shape_type === "circle") {
          map_object.addLayer({
            id: _id,
            type: "circle",
            source: _id + "_cluster",
            filter: ["!", ["has", "point_count"]],
            paint: paint_object,
            layout: {
              visibility: visibility,
            },
          });
          map_object.addLayer({
            id: _id + "clusters_container",
            type: "circle",
            source: _id + "_cluster",
            filter: ["has", "point_count"],
            paint: {
              "circle-color": [
                "step",
                ["get", "point_count"],
                "#51bbd6",
                100,
                "#f1f075",
                750,
                "#f28cb1",
              ],
              "circle-radius": [
                "step",
                ["get", "point_count"],
                20,
                100,
                30,
                750,
                40,
              ],
            },
            layout: {
              visibility: visibility,
            },
          });
          map_object.addLayer({
            id: _id + "cluster_count_number",
            type: "symbol",
            source: _id + "_cluster",
            filter: ["has", "point_count"],
            layout: {
              "text-field": "{point_count_abbreviated}",
              "text-size": 12,
              visibility: visibility,
            },
          });
        } else {
          //untuk kasus lainnya cukup menambahkan satu layer
          map_object.addLayer({
            id: _id,
            source: _id,
            type: shape_type,
            paint: paint_object,
            layout: {
              visibility: visibility,
            },
          });
        }
      } else {
        //jika sudah ada layer dengan _id (karena ada update)
        //hanya update visibility dan juga paint_object nya

        //VISIBILITY GENERAL
        map_object.setLayoutProperty(_id, "visibility", visibility);

        //VISIBILITY & CLUSTER
        if (is_cluster && shape_type === "circle") {
          //TRANSISI dari UNCLUSTER --> CLUSTER

          //layer utama
          map_object.removeLayer(_id);
          map_object.addLayer({
            id: _id,
            type: "circle",
            source: _id + "_cluster",
            filter: ["!", ["has", "point_count"]],
            paint: paint_object,
            layout: {
              visibility: visibility,
            },
          });

          //layer clusters_container
          if (!map_object.getLayer(_id + "clusters_container")) {
            map_object.addLayer({
              id: _id + "clusters_container",
              type: "circle",
              source: _id + "_cluster",
              filter: ["has", "point_count"],
              paint: {
                "circle-color": [
                  "step",
                  ["get", "point_count"],
                  "#51bbd6",
                  100,
                  "#f1f075",
                  750,
                  "#f28cb1",
                ],
                "circle-radius": [
                  "step",
                  ["get", "point_count"],
                  20,
                  100,
                  30,
                  750,
                  40,
                ],
              },
              layout: {
                visibility: visibility,
              },
            });
          } else {
            map_object.setLayoutProperty(
              _id + "clusters_container",
              "visibility",
              visibility
            );
          }

          //layer cluster_count_number
          if (!map_object.getLayer(_id + "cluster_count_number")) {
            map_object.addLayer({
              id: _id + "cluster_count_number",
              type: "symbol",
              source: _id + "_cluster",
              filter: ["has", "point_count"],
              layout: {
                "text-field": "{point_count_abbreviated}",
                "text-size": 12,
                visibility: visibility,
              },
            });
          } else {
            map_object.setLayoutProperty(
              _id + "cluster_count_number",
              "visibility",
              visibility
            );
          }
        } else if (!is_cluster && shape_type === "circle") {
          //TRANSISI dari CLUSTER --> UNCLUSTER
          //layer utama
          map_object.removeLayer(_id);
          map_object.addLayer({
            id: _id,
            type: "circle",
            source: _id,
            paint: paint_object,
            layout: {
              visibility: visibility,
            },
          });
          //layer clusters_contaienr
          if (map_object.getLayer(_id + "clusters_container")) {
            map_object.removeLayer(_id + "clusters_container");
          }
          //layer cluster_count_number
          if (map_object.getLayer(_id + "cluster_count_number")) {
            map_object.removeLayer(_id + "cluster_count_number");
          }
        } else if (shape_type === "fill") {
          //untuk kasus fill (Polygon & MultiPolygon)
          //perlu mengubah visibilty dari layer linenya juga
          //karena fill memiliki satu tambahan layer (layer line) untuk garis luar
          map_object.setLayoutProperty(
            _id + "_line_polygon",
            "visibility",
            visibility
          );
        }

        //PAINT_OBJECT
        for (const property in paint_object) {
          map_object.setPaintProperty(_id, property, paint_object[property]);
        }
        if (shape_type === "fill") {
          //untuk kasus fill (Polygon & MultiPolygon)
          //perlu mengubah paint_object dari layer linenya juga
          //karena fill memiliki satu tambahan layer (layer line) untuk garis luar
          for (const property in paint_object_line_polygon) {
            map_object.setPaintProperty(
              _id + "_line_polygon",
              property,
              paint_object_line_polygon[property]
            );
          }
        }
      }
    }
  };

  render() {
    return <section></section>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  form: state.form,
  properties: state.properties,
  layer: state.layer,
});

export default connect(mapStateToProps, {})(LayerBasic);
