/*LIBRARY*/
import React, { useCallback, useEffect, useRef, useState } from "react";
import maplibregl from "maplibre-gl";
import "maplibre-gl/dist/maplibre-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

/*COMPONENT*/
import CheckBox from "../common/CheckBox";
import LayerBasic from "../layer/LayerBasic";
import SearchLocation from "../submit_form/SearchLocation";
import MARKER_GEO from "../layer/MARKER_GEO";

/*REDUX*/
import { set_value } from "../../actions/formActions";
import { set_layer_value } from "../../actions/layerActions";

/*PICTURE*/
import icon_pin from "../../img/icon_sini.svg";
import icon_full_screen_no from "../../img/icon_full_screen_no.svg";
import icon_full_screen_active from "../../img/icon_full_screen_active.svg";
import icon_location from "../../img/location.svg";

/*FUNCTION*/
import useLanguage from "../../hooks/useLanguage";
import is_lat_long_true from "../../validation/is_lat_long_true";

/*DATA*/

/*CONST*/
const basemap_url = process.env.REACT_APP_MAPID_BASEMAP;
const basemap_key = process.env.REACT_APP_BASEMAP_KEY;
const default_viewport = {
  latitude: -6.1784072398481555,
  longitude: 106.84251626772414,
  zoom: 7,
};
const style = {
  padding: "5px",
  color: "#fff",
  cursor: "pointer",
  background: "#0ca5eb",
  borderRadius: "50%",
  borderStyle: "solid",
  borderColor: "#fff",
  borderWidth: 2,
};

const MapComponent = ({
  isFullMap,
  locationStatus,
  set_polygon_feature,
  handleFullMap,
}) => {
  const dispatch = useDispatch();
  const handlers = useRef([]); // Define handlers with useRef
  const { dict, language } = useLanguage();
  const { latitude, longitude } = useSelector((state) => state?.form);
  const { geo_layer_wo_geojson } = useSelector(
    (state) => state?.form,
    shallowEqual
  );

  const { map_object } = useSelector((state) => state?.layer);
  const map_object_ref = useRef(map_object); // To keep track of map object

  const [isSatellite, setIsSatellite] = useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const markerGps = useRef(null);

  const isLocationChangeable = geo_layer_wo_geojson.isLocationChangeable;
  const layer_attach = geo_layer_wo_geojson?.layer_form_list?.[0];

  const set_lat_long = ({ latitude, longitude }) => {
    if (is_lat_long_true({ latitude, longitude })) {
      if (map_object_ref.current) {
        map_object_ref.current.flyTo({
          center: [longitude, latitude],
          zoom: default_viewport.zoom,
        });
      }
    } else {
      if (map_object_ref.current) {
        map_object_ref.current.flyTo({
          center: [default_viewport.longitude, default_viewport.latitude],
          zoom: default_viewport.zoom,
        });
      }
    }
  };

  useEffect(() => {
    if (is_lat_long_true({ latitude, longitude }) && set_polygon_feature) {
      set_polygon_feature({ latitude, longitude });
      const isLocationChangeable = geo_layer_wo_geojson?.isLocationChangeable;
      if (isLocationChangeable) {
        dispatch(set_value("is_location_exist", true));
      }
    }
  }, [
    dispatch,
    latitude,
    longitude,
    geo_layer_wo_geojson,
    set_polygon_feature,
  ]);

  useEffect(() => {
    set_lat_long({ latitude, longitude });
  }, [latitude, longitude]);

  const handleToggleMap = (e) => {
    setIsSatellite(e.target.checked);

    const street_style = `${basemap_url}/styles/street-2d-building/style.json?key=${basemap_key}`;
    const satellite_style = `${basemap_url}/styles/satellite/style.json?key=${basemap_key}`;

    let style_url = e.target.checked ? satellite_style : street_style;

    if (map_object_ref.current) {
      map_object_ref.current.setStyle(style_url);
    }
  };

  const addMarker = () => {
    if (map_object_ref.current) {
      if (markerGps?.current) {
        markerGps.current?.remove();
        markerGps.current = null;
      }

      const markerElement = document.createElement("div");
      for (const item in style) {
        markerElement.style[item] = style[item];
      }

      // if (locationStatus) {
      const marker = new maplibregl.Marker({
        element: markerElement,
        draggable: isLocationChangeable ? true : false,
      })
        .setLngLat([longitude, latitude])
        .addTo(map_object_ref.current);

      markerGps.current = marker;

      marker.on("dragend", (e) => {
        const { lat, lng } = e.target?.getLngLat();
        dispatch(set_value("longitude", lng));
        dispatch(set_value("latitude", lat));
      });
      // }
    }
  };

  useEffect(addMarker, [
    dispatch,
    latitude,
    longitude,
    isLocationChangeable,
    locationStatus,
  ]);

  const setLatLongReducer = useCallback(() => {
    const center = map_object_ref.current.getCenter();
    const latitude = center.lat;
    const longitude = center.lng;
    dispatch(set_value("longitude", longitude));
    dispatch(set_value("latitude", latitude));
  }, [dispatch]);

  useEffect(() => {
    if (map_object_ref.current) {
      if (isFullMap) {
        map_object_ref.current.on("moveend", setLatLongReducer);
      } else {
        map_object_ref.current.off("moveend", setLatLongReducer);
      }
    }
  }, [isFullMap, setLatLongReducer]);

  useEffect(() => {
    const map_object = new maplibregl.Map({
      container: "map_object",
      style: `${basemap_url}/styles/street-2d-building/style.json?key=${basemap_key}`,
      center: [default_viewport?.longitude, default_viewport?.latitude],
      zoom: 7,
      maxPitch: 85,
      projection: "globe",
      attributionControl: false,
    });

    // map_object = map_object; // Save map_object in the ref
    dispatch(set_layer_value("map_object", map_object));
    map_object_ref.current = map_object;

    map_object?.on("load", () => {
      setIsMapLoaded(true);
    });

    return () => {
      // Clean up on unmount
      if (handlers.current.length > 0) {
        handlers.current.forEach((handler) => {
          if (typeof handler.remove === "function") {
            handler.remove();
          }
        });
        handlers.current = []; // Reset handlers
      }

      if (map_object && typeof map_object.remove === "function") {
        map_object.remove();
      }
    };
  }, [dispatch]);

  return (
    <section>
      {isLocationChangeable && (
        <div
          style={{
            position: "absolute",
            top: 10,
            left: "50%",
            zIndex: 2,
            width: "90%",
            transform: "translate(-50%,0)",
          }}
        />
      )}
      <div
        id="satellite-toggle"
        style={{
          position: "absolute",
          top: 10,
          right: 0,
          zIndex: 2,
          color: isSatellite ? "white" : "black",
        }}
      />
      <div
        id="map_object"
        style={
          isFullMap
            ? {
                width: "100%",
                height: "100%",
                visibility: isMapLoaded ? "visible" : "hidden",
                position: "fixed",
                top: 0,
                left: 0,
                overflow: "hidden",
              }
            : {
                width: "100%",
                height: "350px",
                visibility: isMapLoaded ? "visible" : "hidden",
              }
        }
      >
        <div
          style={{
            fontSize: "20px",
            height: "350px",
            zIndex: 4,
            visibility: isMapLoaded ? "hidden" : "visible",
            textAlign: "center",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <b>{dict?.["Peta sedang dimuat"]?.[language]}...</b>
        </div>
        {layer_attach?.geojson?.features?.length > 0 && (
          <>
            <LayerBasic geo_layer={layer_attach} />
            <MARKER_GEO geo_layer={layer_attach} />
          </>
        )}
        {isLocationChangeable && (
          <div
            style={{
              position: "absolute",
              top: "0",
              width: "500px",
              zIndex: 2,
            }}
          >
            <SearchLocation />
          </div>
        )}

        <section className="lat_long_map" style={{ zIndex: 1 }}>
          <section className="sub_lat_long_map">
            <label style={{ color: isSatellite ? "white" : "black" }}>
              <strong>Lat</strong>
            </label>
            <input
              id="lat"
              type="number"
              value={latitude}
              placeholder="Latitude"
              disabled
              style={{ backgroundColor: "white" }}
              // onChange={(e) => this.set_latitude(e?.target?.value)}
            />
          </section>
          <section className="sub_lat_long_map">
            <label style={{ color: isSatellite ? "white" : "black" }}>
              <strong>Long</strong>
            </label>
            <input
              ig="long"
              type="number"
              value={longitude}
              placeholder="Longitude"
              disabled
              style={{ backgroundColor: "white" }}
              // onChange={(e) => this.set_longitude(e?.target?.value)}
            />
          </section>
        </section>

        <table
          style={{
            position: "absolute",
            right: "5px",
            top: "10px",
            fontSize: "15px",
            zIndex: 1,
          }}
        >
          <tbody>
            <tr>
              <td style={{ color: isSatellite ? "white" : "black" }}>
                <b>Satellite</b>
              </td>
              <td>
                <CheckBox
                  text="isSatellite"
                  title={""}
                  value={isSatellite}
                  handle={handleToggleMap}
                />
              </td>
            </tr>
            {isFullMap && (
              <>
                <tr>
                  <td></td>
                  <td>
                    <button
                      text="locationStatus"
                      className="button_action_full_map"
                      title={""}
                      value={locationStatus}
                      onClick={() => this.onGetLocation()}
                    >
                      <img src={icon_location} alt="location" />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <button
                      className="button_action_full_map"
                      onClick={() => handleFullMap()}
                    >
                      <img src={icon_full_screen_no} alt="full_map" />
                    </button>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>

        {isFullMap ? (
          <div className="icon_full_map">
            <img src={icon_pin} alt="icon_pin" />
          </div>
        ) : (
          <button className="button_full" onClick={() => handleFullMap()}>
            <img src={icon_full_screen_active} alt="full_map" />
          </button>
        )}
      </div>
    </section>
  );
};

export default MapComponent;
